import { Link } from "gatsby"
import React from "react"
import abhiAvatar from "../images/abhi.jpeg";

const Header = ({  }) => (
  <nav className="sm:h-screen flex sm:sticky sm:top-0 flex flex-col justify-center p-4 bg-blue-800 print:hidden">
    <div className="m-0 flex flex-col">
      <img className="rounded-full self-center" src={abhiAvatar} width={130} height={130} alt="Abhi Rao" />
    </div>
    <div className="hidden sm:flex sm:flex-col mx-8 my-4 uppercase text-white space-y-2">
      <Link to="/#about">About</Link>
      <Link to="/#skills">Skills</Link>
      <Link to="/#experience">Experience</Link>
      <Link to="/#volunteer_work">Volunteer</Link>
      <Link to="/#education">Education</Link>
      <a download="Abhi Rao - Resume.pdf" href="/exports/index.pdf">Download</a>
    </div>
  </nav>
)

export default Header
